import React from "react";
import { useLocation,Link} from "react-router-dom";
import "./serviceInfo.css";
import servicInfo1 from "../../static/img/Wavy_Tech-03_Single-12 1.png";
import bubbleServ1 from "../../static/img/bubbleService1.png";
import bubbleServ2 from "../../static/img/bubbleService2.png";
import bubbleServ3 from "../../static/img/bubbleService3.png";
import servicInfo2 from "../../static/img/gstBanner.png";
// import servicInfo3 from "../../static/img/mcaBanner.png";
import servicInfo4 from "../../static/img/accountingBanner.png";
import servicInfo5 from "../../static/img/registrationBanner.png";
const ServiceInfo = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const title = searchParams.get("title");
   console.log(title)
  let content = {};
  
  const Income_Tax = {
    bannerOne: {
      topImg: servicInfo1,
      head1: "  Worried about the complexities of Income Tax filing?",
      head2: "Give Our Experts a call now!",
      btn_banner1: "Connect Now!",
    },
    bannerTwo: {
      paraFirstPart: " End to all tax related worries in 3 simple steps ",
      paraSecondPart: "Just few clicks and your business starts growing",
      bubbleOne: ["Contact Us", bubbleServ1],
      bubbleTwo: ["Talk to our experts", bubbleServ2],
      bubbleThree: ["Let us handle your Tax worries", bubbleServ3],
      bubbleFour: ["Let us handle your Tax worries", bubbleServ3],
      btn_banner2: "Contact Us!",
    },
    bannerThree: {
        topBannerThreeHead:" Who should file an Income Tax Return?",
      topTextMain:
        "Every person being a person or firm or company, if his total income during the financial year exceeded the maximum amount which is not chargeable to income-tax, shall, on or before the due date, furnish a return of his income in the prescribed form and verified in the prescribed manner.",
      topList: [
        "Plan 1: For Salaried Income - just share your form 16 and file your ITR in ₹ 999/-.",
        " Plan 2: For Capital Gain - just share your financial information and file your ITR in ₹ 2,999/-.",
        "Plan 3: For Share Trading - just share your trading report and file your ITR in ₹ 4,999/-.",
        " Plan 4: For Foreign Income or Assets - this plan is for individual NRIs and Indians who are living abroad having Income in India and File your ITR in ₹ 7,499/-.",
      ],
      bottomText:
        "Penalty for Late Filing Income Tax Return Taxpayers who do not file their income tax return on time are subject to penalty and charged an interest on the late payment of income tax. Also, the penalty for late filing income tax return on time has been increased recently.The penalty for late filing income tax return is now as follows:",
      bottList: [
        "Late Filing between 1st August and 31st December - Rs.5000",
        "Late Filing After 31st December - Rs.10,000",
        "Penalty if taxable income is less than Rs.5 lakhs - Rs.1000",
      ],
    },
    bannerFour: {
      bannerFourPartFirst: "Why choose Us?",
      bannerFourPartFirst2: "we keep your trust and provide the best services",
      bannerFourBubble1: "Tax Planning Strategies",
      bannerFourBubble1Text:
        "Discover effective tax planning strategies and techniques designed to minimize your tax liabilities and maximize your savings.We offer valuable insights and resources on tax optimization, ensuring you make informed decisions to optimize your financial situation.",

    
      bannerFourBubble2: "Expert Financial Advice",
      bannerFourBubble2Text:
        "Gain access to expert financial advice from qualified professionals who specialize in tax planning, accounting, and financial management.Our team of experienced professionals is dedicated to providing personalized insights and strategies tailored to your unique financial situation.",

     
      bannerFourBubble3: "Expert Accounting Services",
      bannerFourBubble3Text:
        "Take advantage of our expert accounting services provided by certified professionals with extensive industry knowledge.Our team can assist you with bookkeeping, financial statements, audits, and other accounting-related services, ensuring accuracy and compliance with regulations.",

      
      bannerFourBubble4: "Latest Industry Updates",
      bannerFourBubble4Text:
        "Stay up-to-date with the latest industry news, changes in tax laws, and financial regulations through our regularly updated blog section.We ensure that you remain informed about important developments that may impact your financial decisions and tax obligations.",

          bannerFourBubble5: "Customized Solutions",
      bannerFourBubble5Text:
        "Receive personalized and tailored solutions for your specific tax and financial needs.Our website offers consultation services and financial analysis to help you make informed decisions, optimize your tax savings, and achieve your financial goals.",

          bannerFourBubble6: "Deadline Reminders and Alerts",
      bannerFourBubble6Text:
        "Stay on top of important tax deadlines and never miss a filing or payment deadline again.We provide convenient tax deadline reminders and alerts, helping you avoid penalties and ensuring timely submission of your tax returns and payments. Receive notifications via email or SMS to keep you organized and compliant.",
    },
  }
  const Goods_Service_Tax = {
    bannerOne: {
      topImg: servicInfo2,
      head1: "  Worried about the complexities of GST filing?",
      head2: "Give Our Experts a call now!",
      btn_banner1: "Connect Now!",
    },
    bannerTwo: {
      paraFirstPart: " End to all GST related worries in 3 simple steps ",
      paraSecondPart: "Just few clicks and your business starts growing",
      bubbleOne: ["Contact Us", bubbleServ1],
      bubbleTwo: ["Talk to our experts", bubbleServ2],
      bubbleThree: ["Let us handle your GST worries", bubbleServ3],
     
      btn_banner2: "Contact Us!",
    },
    bannerThree: {
        topBannerThreeHead:" What is Goods & Service Tax?",
      topTextMain:
        "Goods and service tax(GST) is an indirect tax which has replaced many indirect taxes in India such as the excise duty, VAT, services tax, etc. GST is levied on the supply of goods and services. Goods and Services Tax Law in India is a comprehensive, multi-stage, destination-based tax that is levied on every value addition. GST is a single domestic indirect tax law for the entire country.Different Types of GST Tax:",
      topList: [
        "State Goods and Services Tax or SGST.",
        "Central Goods and Services Tax or CGST.",
        "Integrated Goods and Services Tax or IGST.",
        
      ],
      bottomText:
        "The businesses registered under GST have to file returns monthly, quarterly and annually based on the category of business through the Government of India's GST portal. They have to provide the details of the sales and purchases of goods and services along with the tax collected and paid.",
      bottList: [
        "GSTR – 1: Outward supplies Return",
        "GSTR - 3B: Summary return of outward supplies and input tax credit claimed, along with payment of tax by the taxpayer.",
        "GSTR – 9: Annual return for normal registered taxpayers",
        "GSTR - 9C: Every registered person whose aggregate turnover during a financial year exceeds Rs.5 crore rupees shall get his accounts audited as specified under sub-section (5) of section 35 of the CGST Act.",
        "GST CMP-08: GST CMP 08 is a special statement-cum-challan to declare the details or summary of his/her self-assessed tax payable for a given quarter. It also acts as a challan for making payment of tax."
      ],
    },
    bannerFour: {
      bannerFourPartFirst: "Why choose Us?",
      bannerFourPartFirst2: "we keep your trust and provide the best services",
      bannerFourBubble1: "Tax Planning Strategies",
      bannerFourBubble1Text:
        "Discover effective tax planning strategies and techniques designed to minimize your tax liabilities and maximize your savings.We offer valuable insights and resources on tax optimization, ensuring you make informed decisions to optimize your financial situation.",

    
      bannerFourBubble2: "Expert Financial Advice",
      bannerFourBubble2Text:
        "Gain access to expert financial advice from qualified professionals who specialize in tax planning, accounting, and financial management.Our team of experienced professionals is dedicated to providing personalized insights and strategies tailored to your unique financial situation.",

     
      bannerFourBubble3: "Expert Accounting Services",
      bannerFourBubble3Text:
        "Take advantage of our expert accounting services provided by certified professionals with extensive industry knowledge.Our team can assist you with bookkeeping, financial statements, audits, and other accounting-related services, ensuring accuracy and compliance with regulations.",

      
      bannerFourBubble4: "Latest Industry Updates",
      bannerFourBubble4Text:
        "Stay up-to-date with the latest industry news, changes in tax laws, and financial regulations through our regularly updated blog section.We ensure that you remain informed about important developments that may impact your financial decisions and tax obligations.",

          bannerFourBubble5: "Customized Solutions",
      bannerFourBubble5Text:
        "Receive personalized and tailored solutions for your specific tax and financial needs.Our website offers consultation services and financial analysis to help you make informed decisions, optimize your tax savings, and achieve your financial goals.",

          bannerFourBubble6: "Deadline Reminders and Alerts",
      bannerFourBubble6Text:
        "Stay on top of important tax deadlines and never miss a filing or payment deadline again.We provide convenient tax deadline reminders and alerts, helping you avoid penalties and ensuring timely submission of your tax returns and payments. Receive notifications via email or SMS to keep you organized and compliant.",

    },
  }
  // const Ministry_Corporate_Affairs = {
  //   bannerOne: {
  //     topImg: servicInfo3,
  //     head1: "Trouble related to Ministry of Corporate Affairs?",
  //     head2: "Give Our Experts a call now!",
  //     btn_banner1: "Connect Now!",
  //   },
  //   bannerTwo: {
  //     paraFirstPart: " End all related worries in 3 simple steps ",
  //     paraSecondPart: "Just few clicks and your business starts growing",
  //     bubbleOne: ["Contact Us", bubbleServ1],
  //     bubbleTwo: ["Talk to our experts", bubbleServ2],
  //     bubbleThree: ["Let us handle your Corporate Affairs worries", bubbleServ3],
  //     btn_banner2: "Contact Us!",
  //   },
  //   bannerThree: {
  //       topBannerThreeHead:" What is Ministry of Corporate Affairs?",
  //     topTextMain:
  //       "The Ministry of Corporate Affairs (MCA) is vested with the powers of regulating the corporate affairs in India through the Companies Act of 1956, Companies Act, 2013, and other allied Acts. This article looks at the various functions of the regulatory body and the services provided by it.",
  //     topList: [
  //       "Private Limited: A private company is a company which is owned by non-governmental organisations or a relatively small number of shareholders or members of a company.Usually, a private company does not offer or trade its shares to the general public on the stock exchanges, but rather the private stock of the company is owned and traded. ",
  //       " Public Limited: A public limited company offers shares to the general public and has limited liability. Its stock can be acquired by anyone, either privately through (IPO) initial public offering or via trades on the stock market.. It is strictly regulated and is required to publish its true financial health to its shareholders. ",
  //       "NGO/Section 8 Company: Section 8 Company or a Non-Profit organisation (NPO) is a Company established for promoting commerce, art, science, religion, sports, education, research activities, charity or any other useful social object, provided the profits and donations is applied for promoting only the objects of the company and no dividend is paid to its members or owners.",
       
  //     ],
  //     bottomText:
  //       "",
  //     bottList: [
        
  //     ],
  //   },
  //   bannerFour: {
  //     bannerFourPartFirst: "Why choose Us?",
  //     bannerFourPartFirst2: "we keep your trust and provide the best services",
  //     bannerFourBubble1: "Tax Planning Strategies",
  //     bannerFourBubble1Text:
  //       "Discover effective tax planning strategies and techniques designed to minimize your tax liabilities and maximize your savings.We offer valuable insights and resources on tax optimization, ensuring you make informed decisions to optimize your financial situation.",

    
  //     bannerFourBubble2: "Expert Financial Advice",
  //     bannerFourBubble2Text:
  //       "Gain access to expert financial advice from qualified professionals who specialize in tax planning, accounting, and financial management.Our team of experienced professionals is dedicated to providing personalized insights and strategies tailored to your unique financial situation.",

     
  //     bannerFourBubble3: "Expert Accounting Services",
  //     bannerFourBubble3Text:
  //       "Take advantage of our expert accounting services provided by certified professionals with extensive industry knowledge.Our team can assist you with bookkeeping, financial statements, audits, and other accounting-related services, ensuring accuracy and compliance with regulations.",

      
  //     bannerFourBubble4: "Latest Industry Updates",
  //     bannerFourBubble4Text:
  //       "Stay up-to-date with the latest industry news, changes in tax laws, and financial regulations through our regularly updated blog section.We ensure that you remain informed about important developments that may impact your financial decisions and tax obligations.",

  //         bannerFourBubble5: "Customized Solutions",
  //     bannerFourBubble5Text:
  //       "Receive personalized and tailored solutions for your specific tax and financial needs.Our website offers consultation services and financial analysis to help you make informed decisions, optimize your tax savings, and achieve your financial goals.",

  //         bannerFourBubble6: "Deadline Reminders and Alerts",
  //     bannerFourBubble6Text:
  //       "Stay on top of important tax deadlines and never miss a filing or payment deadline again.We provide convenient tax deadline reminders and alerts, helping you avoid penalties and ensuring timely submission of your tax returns and payments. Receive notifications via email or SMS to keep you organized and compliant.",

  //   },
  // }
  const Accounting = {
    bannerOne: {
      topImg: servicInfo4,
      head1: " Worried about Accounting related Issues?",
      head2: "Give Our Experts a call now!",
      btn_banner1: "Connect Now!",
    },
    bannerTwo: {
      paraFirstPart: " End all Accounting related Issues in 3 simple steps ",
      paraSecondPart: "Just few clicks and your business starts growing",
      bubbleOne: ["Contact Us", bubbleServ1],
      bubbleTwo: ["Talk to our experts", bubbleServ2],
      bubbleThree: ["Let us handle your Accounting worries", bubbleServ3],
      btn_banner2: "Contact Us!",
    },
    bannerThree: {
        topBannerThreeHead:"What is Accounting? Very Briefly!",
      topTextMain:
        "Accounting, which is often just called Accounting, is the process of measuring, processing, and sharing financial and other information about businesses and corporations.",
      topList: [
        "Financial Accounting: The balance sheet, income statement, and cash flow statement summarise all financial transactions that occurred within a specific accounting period. The process of preparing interim and annual financial statements is called financial accounting.",
        " Managerial Accounting: Managerial accounting and financial accounting use many of the same data, but they organize and use the data in different ways. In managerial accounting, the accountant needs to prepare peroidical reports for actionable insights.",
        "Cost Accounting: Cost decisions are taken after considering the insights of cost accountants. Cost accounting looks at all of the costs of making a product. The relevant managers make use of the costing decisions to check the viability of the products and improve costing decisions.",
        "Accounting requires skills: There are many different kinds of people who become accountants. In general, though, accountants need to pay close attention to details because they need to be able to spot and fix small mistakes or oddities in a company's financial statements. Problem-solving also requires being able to think logically.",
      ],
      bottomText:
        "Accounting is the processor keeping the accounting books of the financial transactions of the company. The accountants summarize the transactions in the form of journal entries. These entries are used in bookkeeping. The books of accounts are prepared by the accountants as per the regulation of the auditors and various regulating bodies. The accountants might follow the Generally Accepted Accounting Principles (GAAP) or the IFRS (International Financial Reporting Standards) principles",
      bottList: [
        "What does the word ledger mean? A ledger is a book with accounts where the categorized and summarised information from the journals is written as debits and credits. The second entry book is another name for it. The information needed to make financial statements is in the ledger.",
        "What are the basics of accounting? Simple accounting is keeping track of the money that comes in and out of business. It involves evaluating, summarising, and reporting on these transactions to regulators, watchdog groups, and tax collection groups.",
        "What is the accounting journal? A journal of accounts is a complete record of how a business handles its money. It is also called the book of original entry because it is the first place where transactions are written down.",
      ],
    },
    bannerFour: {
      bannerFourPartFirst: "Why choose Us?",
      bannerFourPartFirst2: "we keep your trust and provide the best services",
      bannerFourBubble1: "Tax Planning Strategies",
      bannerFourBubble1Text:
        "Discover effective tax planning strategies and techniques designed to minimize your tax liabilities and maximize your savings.We offer valuable insights and resources on tax optimization, ensuring you make informed decisions to optimize your financial situation.",

    
      bannerFourBubble2: "Expert Financial Advice",
      bannerFourBubble2Text:
        "Gain access to expert financial advice from qualified professionals who specialize in tax planning, accounting, and financial management.Our team of experienced professionals is dedicated to providing personalized insights and strategies tailored to your unique financial situation.",

     
      bannerFourBubble3: "Expert Accounting Services",
      bannerFourBubble3Text:
        "Take advantage of our expert accounting services provided by certified professionals with extensive industry knowledge.Our team can assist you with bookkeeping, financial statements, audits, and other accounting-related services, ensuring accuracy and compliance with regulations.",

      
      bannerFourBubble4: "Latest Industry Updates",
      bannerFourBubble4Text:
        "Stay up-to-date with the latest industry news, changes in tax laws, and financial regulations through our regularly updated blog section.We ensure that you remain informed about important developments that may impact your financial decisions and tax obligations.",

          bannerFourBubble5: "Customized Solutions",
      bannerFourBubble5Text:
        "Receive personalized and tailored solutions for your specific tax and financial needs.Our website offers consultation services and financial analysis to help you make informed decisions, optimize your tax savings, and achieve your financial goals.",

          bannerFourBubble6: "Deadline Reminders and Alerts",
      bannerFourBubble6Text:
        "Stay on top of important tax deadlines and never miss a filing or payment deadline again.We provide convenient tax deadline reminders and alerts, helping you avoid penalties and ensuring timely submission of your tax returns and payments. Receive notifications via email or SMS to keep you organized and compliant.",

    },
  }
  const Audit_Inspection = {
    bannerOne: {
      topImg: servicInfo5,
      head1: "Simplfy complexities of Internal Audit?",
      head2: "Give Our Experts a call now!",
      btn_banner1: "Connect Now!",
    },
    bannerTwo: {
      paraFirstPart: " End  all  Internal Audit related worries in 3 simple steps ",
      paraSecondPart: "Just few clicks and your business starts growing",
      bubbleOne: ["Contact Us", bubbleServ1],
      bubbleTwo: ["Talk to our experts", bubbleServ2],
      bubbleThree: ["Let us handle Internal Audit worries", bubbleServ3],
      btn_banner2: "Contact Us!",
    },
    bannerThree: {
        topBannerThreeHead:"Internal Audit?",
      topTextMain:
        "",
      topList: [
        " An Internal Audit usually looks at the processes within a company,  usually focuses on the product or service being provided.",
        "Internal Audit is the depth of review if the product or service meets those specified requirements, it is considered acceptable; if not, it is deemed rejected. On the other hand, an audit is typically a much deeper review of the product or process.",
        "The primary purpose of an Internal audit is to improve processes. On the other hand, inspection is typically used to determine whether the product or service meets specifications. ",
        "Internal Audits focus on the future by identifying weaknesses in the system and looking at opportunities to improve processes. On the other hand, inspections are focused on the past performance of the process."
        
      ],
      bottomText:
        "",
      bottList: [
       
      ],
    },
    bannerFour: {
      bannerFourPartFirst: "Why choose Us?",
      bannerFourPartFirst2: "we keep your trust and provide the best services",
      bannerFourBubble1: "Tax Planning Strategies",
      bannerFourBubble1Text:
        "Discover effective tax planning strategies and techniques designed to minimize your tax liabilities and maximize your savings.We offer valuable insights and resources on tax optimization, ensuring you make informed decisions to optimize your financial situation.",

    
      bannerFourBubble2: "Expert Financial Advice",
      bannerFourBubble2Text:
        "Gain access to expert financial advice from qualified professionals who specialize in tax planning, accounting, and financial management.Our team of experienced professionals is dedicated to providing personalized insights and strategies tailored to your unique financial situation.",

     
      bannerFourBubble3: "Expert Accounting Services",
      bannerFourBubble3Text:
        "Take advantage of our expert accounting services provided by certified professionals with extensive industry knowledge.Our team can assist you with bookkeeping, financial statements, audits, and other accounting-related services, ensuring accuracy and compliance with regulations.",

      
      bannerFourBubble4: "Latest Industry Updates",
      bannerFourBubble4Text:
        "Stay up-to-date with the latest industry news, changes in tax laws, and financial regulations through our regularly updated blog section.We ensure that you remain informed about important developments that may impact your financial decisions and tax obligations.",

          bannerFourBubble5: "Customized Solutions",
      bannerFourBubble5Text:
        "Receive personalized and tailored solutions for your specific tax and financial needs.Our website offers consultation services and financial analysis to help you make informed decisions, optimize your tax savings, and achieve your financial goals.",

          bannerFourBubble6: "Deadline Reminders and Alerts",
      bannerFourBubble6Text:
        "Stay on top of important tax deadlines and never miss a filing or payment deadline again.We provide convenient tax deadline reminders and alerts, helping you avoid penalties and ensuring timely submission of your tax returns and payments. Receive notifications via email or SMS to keep you organized and compliant.",

    },
  }
  if(title === "Income Tax Return"){
    content = Income_Tax;
    console.log(content.bannerOne.topImg)
  }else if(title === "Goods & Service Tax"){
    content = Goods_Service_Tax;

  }else if(title === "Accounting & Book-Keeping"){
    content = Accounting;
  }else if(title === "Internal Audit"){
    content = Audit_Inspection;
  }else{

  }
  return (
    <>
      {/* <h1 className="text-center">{title}</h1> */}
      
        
          <div className="container">
            {/* First Banner */}
            <div
              className="row col-md-12 banner_within_services"
              style={{ backgroundColor: "white" }}
            >
              <div className="row col-md-6">
                <img
                className="topMostImg"
                  src={content.bannerOne.topImg}
                  alt="service img banner 1"
                  height="300rem"
                />
              </div>
              <div className="row col-md-6">
                <h1 className="text-center" style={{ fontWeight: "700" }}>
                    {content.bannerOne.head1}
                  
                </h1>
                <h2 className="text-center">{content.bannerOne.head2}</h2>
                <Link to="tel:123123123132"> <button className="connect_btn_service">{content.bannerOne.btn_banner1}</button>
                </Link>
              </div>
            </div>
            {/* Second Banner */}

            <div
              className="row col-md-12 banner_within_services"
              style={{ backgroundColor: "rgba(142, 146, 186, 0.24)" }}
            >
              <div className="row col-md-12">
                <p className="text-center" style={{ fontSize: " xx-large" }}>
                 {content.bannerTwo.paraFirstPart} <br />
                  <small className="text-center" style={{ fontSize: " large" }}>
                    {content.bannerTwo.paraSecondPart}
                    
                  </small>
                </p>
              </div>
              <div className="row col-md-4 service_banner_bubble">
                <p className="text-center">{content.bannerTwo.bubbleOne[0]}</p>
                <img src={bubbleServ1} alt="" />
              </div>
              <div className="row col-md-4 service_banner_bubble">
                <p className="text-center">{content.bannerTwo.bubbleTwo[0]}</p>
                <img src={bubbleServ2} alt="" />
              </div>
              <div className="row col-md-4 service_banner_bubble">
                <p className="text-center">{content.bannerTwo.bubbleThree[0]}</p>
                <img src={bubbleServ3} alt="" />
              </div>
              <div className="text-center">
              <Link to="https://wa.me/123123123132"> <button className="connect_btn_service">{content.bannerTwo.btn_banner2}</button>
              </Link>
              </div>
            </div>
            {/* Third Banner */}

            <div
              className="row col-md-12 banner_within_services"
              style={{ backgroundColor: "white" }}
            >
              <p className="text-center" style={{ fontSize: " xx-large" }}>
               {content.bannerThree.topBannerThreeHead}
              </p>
              <div className="row col-md-12">
              {content.bannerThree.topTextMain}
                <ul>
                  <li>
                  {content.bannerThree.topList[0]}
                  </li>
                  <li>
                  {content.bannerThree.topList[1]}
                  </li>
                  <li>
                  {content.bannerThree.topList[2]}
                  </li>
                  <li>
                  {content.bannerThree.topList[3]}
                  </li>
                </ul>
                <p></p>
              </div>
              <div className="row col-md-12">
              {content.bannerThree.bottomText}
                <ul>
                  <li>
                   {content.bannerThree.bottList[0]}
                  </li>
                  <li> {content.bannerThree.bottList[1]}</li>
                  <li>
                  {content.bannerThree.bottList[2]}
                  </li>
                  <li>
                  {content.bannerThree.bottList[3]}
                  </li>
                  <li>
                  {content.bannerThree.bottList[4]}
                  </li>
                </ul>
              </div>
            </div>
            {/* Fourth Banner */}
            <div
              className="row col-md-12 banner_within_services"
              style={{ backgroundColor: "rgba(142, 146, 186, 0.24)" }}
            >
              <p className="text-center" style={{ fontSize: " xx-large" }}>
                {content.bannerFour.bannerFourPartFirst}
                <br />
                <small className="text-center" style={{ fontSize: " large" }}>
                {content.bannerFour.bannerFourPartFirst2}
                </small>
              </p>
              <div className="row col-md-4 service_banner_bubble">
                <p className="text-center">
                  <strong>{content.bannerFour.bannerFourBubble1}</strong>
                </p>
                <p  className="" style={{ fontSize: "small",textAlign:"justify" }}>
                {content.bannerFour.bannerFourBubble1Text}
                </p>
              </div>
              <div className="row col-md-4 service_banner_bubble">
                <p className="text-center">
                  <strong>{content.bannerFour.bannerFourBubble2}</strong>
                </p>

                <p className="" style={{ fontSize: "small",textAlign:"justify"  }}>
                {content.bannerFour.bannerFourBubble2Text}
                </p>
              </div>
              <div className="row col-md-4 service_banner_bubble">
                <p className="text-center">
                  <strong>{content.bannerFour.bannerFourBubble3}</strong>
                </p>

                <p className="" style={{ fontSize: "small" ,textAlign:"justify" }}>
                {content.bannerFour.bannerFourBubble3Text}
                </p>
              </div>
              <div className="row col-md-4 service_banner_bubble">
                <p className="text-center">
                  <strong>{content.bannerFour.bannerFourBubble4}</strong>
                </p>

                <p className="" style={{ fontSize: "small" ,textAlign:"justify" }}>
                {content.bannerFour.bannerFourBubble4Text}
                </p>
              </div>
              <div className="row col-md-4 service_banner_bubble">
                <p className="text-center">
                  <strong>{content.bannerFour.bannerFourBubble5}</strong>
                </p>

                <p className="" style={{ fontSize: "small",textAlign:"justify"  }}>
                {content.bannerFour.bannerFourBubble5Text}
                </p>
              </div>
              <div className="row col-md-4 service_banner_bubble">
                <p className="text-center">
                  <strong>{content.bannerFour.bannerFourBubble6}</strong>
                </p>

                <p className="" style={{ fontSize: "small",textAlign:"justify"  }}>
                {content.bannerFour.bannerFourBubble6Text}
                </p>
              </div>
            </div>
          </div>
        
      
    </>
  );
};

export default ServiceInfo;
