import React from 'react'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import "./swiper.css";
// import itr from "../../static/img/income-tax-return-itr.jpg"
// import gst from "../../static/img/gst.jpg";
// import accBook from "../../static/img/Accounting-and-Bookkeeping-Services.jpg";
// import audit from "../../static/img/Auditing.jpeg";

const SwiperComponent = () => {
    const handleSlideChange = (e) => {
        // handle slide change event
        // console.log('Slide changed to index:', e.item);
      };
    
      const responsive = {
        0: { items: 1 },
        768: { items: 2 },
        1024: { items: 3 },
      };
    
  return (
    <div className='swiper-container'>
    <AliceCarousel
    items={[
        <div className='custom_image'><p> क्या आपने वित्त वर्ष 2022-2023 के लिए आईटीआर दाखिल किया है ?<br/>

        आखिरी तारीख 31 जुलाई 2023 है।<br/>

        यदि नहीं तो हम आपको ITR भरने में और रिफंड दिलाने में मदद कर सकते हैं।</p></div>,
        <div className='custom_image'><p> Have you filed ITR for 2022-2023 F.Y ? <br/>

        The last date is 31st July 2023.<br/>

        If not done then we can help you to submit ITR & process for refund if available.</p></div>,
          <div className='custom_image'><p> क्या आपने वित्त वर्ष 2022-2023 के लिए आईटीआर दाखिल किया है ?<br/>

          आखिरी तारीख 31 जुलाई 2023 है।<br/>
  
          यदि नहीं तो हम आपको ITR भरने में और रिफंड दिलाने में मदद कर सकते हैं।</p></div>,
           <div className='custom_image'><p> Have you filed ITR for 2022-2023 F.Y ? <br/>

           The last date is 31st July 2023.<br/>
   
           If not done then we can help you to submit ITR & process for refund if available.</p></div>
  
    ]}
    responsive={responsive}
    autoPlay
    autoPlayInterval={10000}
    infinite
    disableButtonsControls
    onSlideChanged={handleSlideChange}
  />
  </div>
  )
}

export default SwiperComponent