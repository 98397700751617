import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const TitleUpdater = () => {
  const location = useLocation();

  useEffect(() => {
    // Update document title based on the current route
    if (location.pathname === '/') {
      document.title = 'DMT Accountancy';
    } else if(location.pathname === '/about') {
      document.title = 'About';
    } else if(location.pathname === '/services'){
        document.title = 'Services';
    }
    else if(location.pathname === '/services-layer1'){
        document.title = 'Services-Next';
    }
    else if(location.pathname === '/contact'){
        document.title = 'Contact';
    }
    else if(location.pathname === '/list-services'){
        document.title = 'listServices';
    }
    else if(location.pathname === '/careers'){
        document.title = 'Careers';
    }
    else if(location.pathname === '/login'){
        document.title = 'Login';
    }
    else if(location.pathname === '/serviceInfo'){
        document.title = 'ServiceInfo';
    }
  }, [location]);

  return null; // This component doesn't render anything
};

export default TitleUpdater;
