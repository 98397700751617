import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { RiKakaoTalkLine, RiWhatsappFill } from "react-icons/ri";
import  {FaLessThan} from "react-icons/fa";
import {FaGreaterThan} from "react-icons/fa";

import LoopingText from "./LoopingText.jsx";
import "./home.css";

import SwiperComponent from "../swiper/SwiperComponent.jsx";

//image import home
import itr from "../../static/img/incomefin2.png";

import gst from "../../static/img/gstfin2.png";


import accBook from "../../static/img/bookfin4.png"


import audit from "../../static/img/InternalAuditCanva.png";

const Home = () => {
  //State for the modal

  const [showModal, setShowModal] = useState(false);
  const [showImgModal,setShowImgModal] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);

  const [clickedImg,setClickedImg] = useState("");


  const textArray = [
    "Are you liable to file ITR? Have you Filed ITR? We can assist you in filing ITR?",
    "Are you liable to file GST? Have you registered for GST? We can assist you in filing GST?",
    "Are you struggling with Book-keeping & Accounting? Do you manage your Books & Accounts Seamlessly? We can assist you in filing Book-keeping?",
    "Are you planning Internal Audit for your Firm? Need to evaluate your Company's internal controls,corporate practices, processes, and methods.? We can assist you in Executing Internal Audit?"
  ];

  const closeIt = () => {
    setShowModal(false);
    setShowBackdrop(false);
  };



  useEffect(() => {
    // console.log(textArray[0])
    const timer = setTimeout(() => {
      setShowModal(true);
      setShowBackdrop(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  const handleImgClick = (event) =>{
    const clickedImageId = event.target.id;
    // console.log("Clicked image ID:", clickedImageId);
    setClickedImg(clickedImageId);
    setShowImgModal(true);
    setShowBackdrop(true);


  }
  const closeImgModal = () =>{
    setShowImgModal(false);
    setShowBackdrop(false);
  }
  //change on left arrow click
  const handleLeftClick = () =>{
    if (clickedImg === "itr"){
      setClickedImg("auditing");


    }
    else if(clickedImg === "gst"){
      setClickedImg("itr");

    }else if(clickedImg === "bookeep"){
      setClickedImg("gst");
    }
    else if(clickedImg === "auditing"){
      setClickedImg("bookeep");
    }
  }
  //handle right arrow click
  const handleRightClick = () =>{
   if(clickedImg === "auditing"){
    setClickedImg("itr");

   }else if(clickedImg === "itr"){
    setClickedImg("gst");

   }else if(clickedImg === "gst"){
     setClickedImg("bookeep")
     
   }else if(clickedImg === "bookeep"){
    setClickedImg("auditing");
   }
  }
  return (
    <>

      {/* img modal */}
      <div
        className={`modal fade modal-lg ${showImgModal ? "show d-flex " : ""}`}
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden={!showImgModal}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
         
             <span className="modal_left_icon" onClick={handleLeftClick}><FaLessThan size={40} /></span>
              <span className="modal_right_icon" onClick={handleRightClick}><FaGreaterThan size={40} /></span>
              <span className="modal_close_icon" >
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeImgModal}
              ></button>
              </span>
            
              {clickedImg === "itr" && <img src={itr} alt="pic1" width={770} style={{opacity:"0.8",borderRadius:"5px"}}/>} 
              {clickedImg === "itr" &&  <span className="modal_text"><LoopingText text={textArray[0]} /></span>  }

              {clickedImg === "gst" && <img src={gst} alt="pic2" width={770} style={{opacity:"0.8",borderRadius:"5px"}}/>} 
              {clickedImg === "gst" && <span className="modal_text"><LoopingText text={textArray[1]} /></span> } 

              {clickedImg === "bookeep" && <img src={accBook} alt="pic3" width={770} style={{opacity:"0.8",borderRadius:"5px"}}/>} 
              {clickedImg === "bookeep" && <span className="modal_text"><LoopingText text={textArray[2]} /></span> }

              {clickedImg === "auditing" && <img src={audit} alt="pic4" width={770} style={{opacity:"0.8",borderRadius:"5px"}}/>} 
              {clickedImg === "auditing" && <span className="modal_text"><LoopingText text={textArray[3]} /></span> }  
             

            
          </div>
        </div>
      </div>
      {/* MODAL MESSAGE */}
      <div
        className={`modal fade modal-lg ${showModal ? "show d-flex " : ""}`}
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden={!showModal}
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content  ">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Important Notice!
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeIt}
              ></button>
            </div>
            <div className="modal-body text-center">
              <span className="text-center" style={{ color: "red" }}>
                क्या आपने वित्त वर्ष 2022-2023 के लिए आईटीआर दाखिल किया है ?
                <br />
                आखिरी तारीख 31 जुलाई 2023 है।
                <br />
                यदि नहीं तो हम आपको ITR भरने में और रिफंड दिलाने में मदद कर सकते
                हैं।
                <br />
                <br />
                Have you filed ITR for 2022-2023 F.Y ? <br />
                The last date is 31st July 2023.
                <br />
                If not done then we can help you to submit ITR & process for
                refund if available.
                <br />
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* main body home  */}

      <div className="card_container">
        <div className="container ">
          <div className="row">
            <div className=" col-md-6 mb-1 img_main_div">
              <div className="img_main_div_blur">
               
                <img id="itr" onClick={(event)=>handleImgClick(event)} className="img_home" height="170px" src={itr} alt="im1" />
              </div>
            </div>

            <div className=" col-md-6 mb-1 img_main_div">
              <div className="img_main_div_blur">
                <img id="gst" onClick={handleImgClick} className="img_home" height="170px" src={gst} alt="im2" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className=" col-md-6 img_main_div">
              <div className="img_main_div_blur">
                <img
                  id="bookeep"
                  onClick={handleImgClick}
                  className="img_home"
                  height="170px"
                  src={accBook}
                  alt="im3"
                />
              </div>
            </div>
            <div className=" col-md-6 img_main_div">
              <div className="img_main_div_blur">
                <img
                 id="auditing"
                 onClick={handleImgClick}
                  className="img_home"
                  height="170px"
                  src={audit}
                  alt="im4"
                />
              </div>
            </div>
          </div>
          <div className="swiper_div">
            <SwiperComponent />
          </div>
          <div className="row card_container_inner">
            <div className="home_container"></div>

            {/* sticky buttons */}
            <nav className="sticky_btn">
              <ul>
                <li className="sticky_btn_l2">
                  <Link to="tel:8368408171">
                    <RiKakaoTalkLine size={"3rem"} color="skyblue" />
                  </Link>
                  <span>Talk with Expert</span>
                </li>
                <li className="sticky_btn_l1">
                  <Link to="https://wa.me/8368408171">
                    <RiWhatsappFill size={"3rem"} color="green" />
                  </Link>
                  <span>Chat with Expert</span>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

      {/* Backdrop Overlay */}
      {showBackdrop && <div className="backdrop"></div>}
    </>
  );
};

export default Home;
