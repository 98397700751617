import React from "react";
import "./services.css";
import { Link } from "react-router-dom";
import { WebRoutes } from "../../routes.jsx";

import { Card, Row, Col } from "react-bootstrap";


import accIcon from "../../static/img/accountingIcon.png";
import taxIcon from "../../static/img/taxIcon.png";
import gstIcon from "../../static/img/goodsIcon.png";
import auditIcon from "../../static/img/inspectionIcon.png";
const Services = () => {
  const servList = [
    {
      id: 0,
      icon: taxIcon,
      title: "Income Tax Return",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      subServices: [
        "Income Tax Return Filing",
        "ITR-1",
        "ITR-2",
        "ITR-3",
        "ITR-4",
        "ITR-5",
        "ITR-6",
        "ITR-7",

        "Lower TDS Certificate",
        "Tax Planning & Management",
        "TDS Return",
      ],
    },

    {
      id: 1,
      icon: gstIcon,
      title: "Goods & Service Tax",
      description:
        "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      subServices: [
        "GST Registration",

        "GST Return Filing",
        "GSTR-1",
        "GSTR-3B",
        "GSTR-9",
        "GSTR-9C",
        "CMP-08",

        "GST Refund",
      ],
    },
    {
      id: 2,
      icon: accIcon,
      title: "Accounting & Book-Keeping",
      description:
        "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      subServices: [
        "Company Registration/Incorporation(Private)",
        "Company Registration/Incorporation(Public)",
        "NGO/Section-8 Company",
      ],
    },
    {
      id: 3,
      icon: auditIcon,
      title: "Internal Audit",
      description:
        "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      subServices: ["lorem", "ipsum", "foflr", "fdfgdf"],
    },
   
  ];
  return (
    <>
      <div className="service_list">
      <div className="contact-us-section top_section">
          <div className="container contact_box">
            <div className=" row services_img"></div>
            <div className="row sibling_there_img">
              <div className="col-lg-6 col-md-12 left_content_about ">
                {/* left_content_about_inner */}
                <div className="left_content_contact_inner_serv ">
                  <h1>Services</h1>
                  <p className="about_text_p">What do we offer?</p>
                  <p className="about_text_p">
                  Customer satisfaction is our only motto, We are still in touch with our first client long-lasting relationship with our clients is our top most precedence.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12"></div>
              <div className="row">
                <div className="col-lg-6 col-md-12"></div>
              </div>
            </div>
          </div>
        </div>
        <h1 className="text-center">Our Services</h1>
        <Row>
          {servList.map((service, index) => (
            <Col className="serv_Card" sm={6} md={4} lg={3} key={index}>
              <div>
                <Link to={{pathname:WebRoutes.SERVICE_INFO,
                search:"?title=" + encodeURIComponent(service.title) }} >
                  <Card className="service-card">
                    <Card.Body>
                      <div>
                        <img src={service.icon} alt="acc" width={60}/>

                      </div>

                      <Card.Title>{service.title}</Card.Title>
                    </Card.Body>
                  </Card>
                </Link>
              </div>
            </Col>
          ))}
         
        </Row>
        <div className="div_more_serviceLink text-center">
            <Link className="service_list_link" to={WebRoutes.LIST_SERVICES}>More Services</Link>
          </div>
      </div>
    </>
  );
};

export default Services;
