export const WebRoutes = {
    HOME: "/",
    ABOUT_US: "/about",
    SERVICES: "/services",
    SERVICES_LAYER1: "/services-layer1",
    CONTACT_US: "/contact",
    LIST_SERVICES: "/list-services",
    CAREERS:"/careers",
    DASHBOARD:"/dashboard",
    LOGIN:"/login",
    SERVICE_INFO: "/serviceInfo",
  };
