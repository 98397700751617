import React from 'react';
import Typewriter from 'typewriter-effect';
const LoopingText = ({ text }) => {
  

    return (
        <Typewriter
          options={{
            strings: [text], // An array of strings to type
            autoStart: true, // Automatically starts typing when mounted
            loop: Infinity, // Loops through the strings infinitely
            delay: 20,  //adjust the typing speed
            deleteSpeed: 20, //adjust delete speed
          }}
        />
      );
};

export default LoopingText;


