import React from "react";
import "./servicesLay1.css";
// import { WebRoutes } from "../../routes.jsx";
import { Card, Row, Col } from "react-bootstrap";

// import { AiFillTrademarkCircle } from "react-icons/ai";
// import { GiBullseye } from "react-icons/gi";
// import { TbDiamondFilled } from "react-icons/tb";
// import { BsHourglassSplit, BsFillCalendarCheckFill } from "react-icons/bs";
// import { RiFileSearchFill } from "react-icons/ri";
// import { TbGeometry } from "react-icons/tb";
// import { FaStamp } from "react-icons/fa";
// import { Link } from "react-router-dom";
import {  useParams } from "react-router-dom";

const ServicesLayer1 = ({ servList }) => {
  // console.log("layer1");
  // console.log(WebRoutes.SERVICES_LAYER1);
  // console.log(servList);
  const { index } = useParams();
  const selectedServices = servList[index].subServices;

  return (
    <>
    <div className="contact-us-section top_section">
          <div className="container contact_box">
            <div className=" row services_img"></div>
            <div className="row sibling_there_img">
              <div className="col-lg-6 col-md-12 left_content_about ">
                {/* left_content_about_inner */}
                <div className="left_content_contact_inner ">
                  <h1>Services</h1>
                  <p className="about_text_p">What do we offer?</p>
                  {/* <p className="about_text_p">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eos illo, asperiores, numquam quas quo beatae incidunt officia perferendis neque cum quidem temporibus nesciunt nisi maiores, hic soluta eveniet sunt magni?
                  </p> */}
                </div>
              </div>
              <div className="col-lg-6 col-md-12"></div>
              <div className="row">
                <div className="col-lg-6 col-md-12"></div>
              </div>
            </div>
          </div>
        </div>
      <div className="inner_Padding">
        <div className="container ">
          <div className="row img_here">
            <Row>
              {selectedServices.map((item, idx) => (
                <Col className="serv_Card_inner" sm={6} md={4} lg={3} key={idx}>
                  <div>
                    <Card className="card_div">
                      <Card.Body>
                        <Card.Text>{item}</Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesLayer1;
