import React from "react";
import "./contact.css";
import { BiSupport } from "react-icons/bi";
import { AiFillMail } from "react-icons/ai";
import { FaBuilding } from "react-icons/fa";
const Contact = () => {

  return (
    <>
      <div className="contact-us-container">
        {/* First Part */}
        <div className="contact-us-section top_section">
          <div className="container contact_box">
            <div className=" row contact_img"></div>
            <div className="row sibling_there_img">
              <div className="col-lg-6 col-md-12 left_content_about ">
                {/* left_content_about_inner */}
                <div className="left_content_contact_inner ">
                  <h1>Contact Us</h1>
                  <p className="about_text_p">What do we do?</p>
                  <p className="about_text_p">
                    Over the years a lot has changed, but our goals remain the
                    same.
                  </p>
                </div>
              </div>
              {/* <div className="col-lg-6 col-md-12"></div>
              <div className="row">
                <div className="col-lg-6 col-md-12"></div>
              </div> */}
            </div>
          </div>
        </div>

        {/* Second Part */}
        <div className="container mid_part_contact">
          <div className="row">
            <div className="col-lg-4 col-md-12">
              <div className="item">
                <div className="content contact_margin">
                  <BiSupport size={"40px"} />
                  <h2 className="about_text_p"><strong>Call Us</strong></h2>
                  <p className="about_text_p">+91 8368408171, 0120-4151488</p>
                </div>
              
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="item">
                <div className="content contact_border_left contact_margin">
                  <AiFillMail size={"40px"} />
                  <h2 className="about_text_p"><strong>E-mail Us</strong></h2>
                  <p className="about_text_p">contact@dmtgroup.in</p>
                </div>
              
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="item">
                <div className="content contact_border_left contact_margin">
                  <FaBuilding size={"40px"} />
                  <h2 className="about_text_p"><strong>Meet Us</strong></h2>
                  <p className="about_text_p">
                    516, Vishal Chamber, Sector-18, Noida-201301, Uttar Pradesh
                  </p>
                </div>
              
              </div>
            </div>
          </div>
        </div>

        
        <div className="map-container align-items-center">

          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7008.042606746342!2d77.325308!3d28.569123000000005!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce521926ed7fd%3A0x7ed73f3f40340355!2sDialmytrip%20Tech%20Private%20Limited!5e0!3m2!1sen!2sin!4v1685795792545!5m2!1sen!2sin" height="100%" style={{border:0}} allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="DMT Map"></iframe>
         
          

        </div>
      </div>
    </>
  );
};

export default Contact;
