import React, { useRef, useState} from "react";
import { Link } from "react-router-dom";
import { WebRoutes } from "../../routes.jsx";
// import axios from "axios";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaTimes } from "react-icons/fa";
import ReCAPTCHA from "react-google-recaptcha";
import brandLogo from "../../static/img/dmtlogo2.png";
import "./header.css";

const Header = () => {
  const recaptchaRef = useRef(null);
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    option: "",
  });
  const [click, setClick] = useState(false);
  const [formShow, setFormShow] = useState(false);

  const [isSubmitDisabled,setIsSubmitDisabled] = useState(true);
  const navRef = useRef();
  const formRef = useRef(null);



 // Click event handler to close the form when clicked outside
//  const handleOutsideClick = (event) => {
//   if (formRef.current && !formRef.current.contains(event.target)) {
//     setFormShow(false);
   
//   }
// };

// // Add click event listener when the component mounts
// useEffect(() => {
//   document.addEventListener("click", handleOutsideClick);
//   return () => {
//     // Remove the click event listener when the component unmounts
//     document.removeEventListener("click", handleOutsideClick);
//   };
// }, []);




  // consult form
 //recaptcha
 const verifyHandler = (captcha) =>{
  // console.log(captcha)
 
  //  setIsVerify(false);
  setIsSubmitDisabled(false);

  
 }
  //show consult form on hover
  const formShowHandle = () => {
    setFormShow(!formShow);
    // setFormShow("block");
    // alert("hii")
    // console.log("working")
  };


  // show navbar
  const showNavbar = () => {

    navRef.current.classList.toggle("responsive_nav");
    setClick(!click);
  };
  //handle cancel
  const handleCancel = (e) => {
    e.preventDefault();
    // setConsult(!consult);
    // Call the reset method on the ReCAPTCHA component
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
      setIsSubmitDisabled(true);
    }
    setFormData({
      name: "",
      email: "",
      phone: "",
      option: "",
    });
    setFormShow(!formShow);
  };






  //handle change

 
  
  const handleChange = (event) => {
    const { name, value } = event.target;
    if(name === 'phone'){
      // console.log(value.length)
      const numericValue = value.replace(/\D/g,"");

  
    if (numericValue.length < 10 || !['6', '7', '8', '9'].includes(numericValue[0])) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: '',
      }));
      // setIsSubmitDisabled(true);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: numericValue,
      }));
      // setIsSubmitDisabled(false);
    }
  }
    else if(name === 'option'){
      if(value === ''){
        setIsSubmitDisabled(true);
      }else{
        // setIsSubmitDisabled(false);
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
    else{
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

  };
  // const isSubmitDisabled = formData.phone.length !== 10 || !phonePattern.test(formData.phone);
 
  // const sendFormData = (formData) => {
  //   // Make an HTTP request to the backend  () (http://localhost:8000/)
  //   setIsSubmitDisabled(true);
  //   if (!formData) {
  //     return false;
  //   } else {
  //     axios
  //       .post("https://dmt-backend-database.onrender.com/form-data", formData)
  //       .then(() => {
  //         console.log(formData);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  // };


  // encrypt : 6053b90a-c0ee-4611-8133-42d81f1828dc
  //handle consult form submit
  const handleSubmit = (event) => {
    // prevent page refresh
    event.preventDefault();
   
  
    if(formData.name !== '' && formData.email !== '' && formData.option !== '' && formData.phone !== ''){
      const config = {
            SecureToken: "22f70113-85a9-418b-ba9c-074e024ade46",
        To: "rajeshquicksilver56@gmail.com",
        From: formData.email,
        Subject: "Query Form",
        Body: `<html>
            <table >
            <tr>
            <td><img src="https://dmtgroup.in/img/logo.png" alt="logo"/></td>
            <td></td>
            <td>${formData.name} Contacted you via Email</td>
            </tr>
            <tr style="border: 1px solid black;">
            <td style="border: 1px solid black;">Name of User:</td>
            <td style="border: 1px solid black;">Email of the User:</td>
            <td style="border: 1px solid black;">Service Selected by User:</td>
            <td style="border: 1px solid black;">User Phone Number:</td>
      
          </tr>
          <tr>
            <td style="border: 1px solid black;">${formData.name}</td>
            <td style="border: 1px solid black;">${formData.email}</td>
            <td style="border: 1px solid black;">${formData.option}</td>
            <td style="border: 1px solid black;">${formData.phone}</td>
      
          </tr> <table/>
      </html>`
      }
      if(window.Email){
        window.Email.send(config).then((message)=>alert(message));
      }
    // sendFormData(formData);
   
    }else{
      alert("Please fill the form completely")
    }
   

    setFormData({
      name: "",
      email: "",
      phone: "",
      option: "",
    });
    // Call the reset method on the ReCAPTCHA component
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
      // setIsVerify(true);
    }
    setFormShow(!formShow);

    // add any additional logic here, such as sending the form data to a server
    /***************************************the backend request code**************************************************** */
  };

  return (
    <>
      {/* <!-- ======= Header ======= -->*/}
  
      <header className={click ? "head" : "head_open"}>
        <Link className="navbar_brand" to={WebRoutes.HOME}>
          <img src={brandLogo} alt="logo" />
        </Link>
        <nav ref={navRef}>
          <Link onClick={showNavbar}  className="nav_link" to={WebRoutes.HOME}>
            Home
          </Link>

          <Link onClick={showNavbar}  className="nav_link" to={WebRoutes.ABOUT_US}>
            About Us
          </Link>

          <Link onClick={showNavbar} className="nav_link" to={WebRoutes.SERVICES}>
            Services
          </Link>

          <Link onClick={showNavbar}  className="nav_link" to={WebRoutes.CONTACT_US}>
            Contact Us
          </Link>
        </nav>
        {!click ? (
          <button onClick={showNavbar} className="nav_btn">
            <GiHamburgerMenu size={"31px"} />
          </button>
        ) : (
          <button onClick={showNavbar} className="nav_btn nav_close_btn">
            <FaTimes size={"31px"} />
          </button>
        )}
        <div  >
          <p
           
           onClick={formShowHandle}
            className="top_btn btn btn-primary ms-2 ms-lg-3 consult_Form_button"
          >
            Consult Now
          </p>
          
         
          
        </div>
        <div   className={!formShow ? "consult_form " : "consult_form_unhide"}>
          <div className="span_div">
            Connect for Expert Advice
            <div className="wa_icon">
              {" "}
              {/* <Link to="https://wa.me/123123123132">
                <BsWhatsapp size={"40px"} color="green" />
              </Link> */}
            </div>{" "}
           
          </div>
          <form onSubmit={handleSubmit} ref={formRef}>
            <input
              type="text"
              placeholder="Enter Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              placeholder="Enter Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              type="phone"
              placeholder="Enter Phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              maxLength={10}
              required
            />
            <select
              name="option"
              value={formData.option}
              onChange={handleChange}
              required
            >
              <option>Select Service</option>

              <option>Income Tax Return</option>
              <option>Goods & Service Tax</option>
              <option>Accounting & Book-Keeping</option>
              <option>Internal Audit</option>
            </select>
            <ReCAPTCHA
             ref={recaptchaRef}
                onChange={(value) => {verifyHandler(value);
                   }}
                sitekey={siteKey}
              />

            <div className="btn_div">
              <button className="consult_btn_can" onClick={handleCancel}>
                Cancel
              </button>
              {/* disabled={!verify} */}
              <button className="consult_btn_sub" disabled={isSubmitDisabled}>Submit</button>
            </div>
          </form>
        </div>
      </header>

      {/* <!-- End Header --> */}
    </>
  );
};

export default Header;
