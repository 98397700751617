import React, { useState } from "react";
import "./about.css";
import { BsLinkedin } from "react-icons/bs";
// import { Card, Row, Col } from "react-bootstrap";
// import AshishSir from "../../static/img/AshishSir.jpg";
import Deepaksir from "../../static/img/Deepaksir.png";
import ManojSir from "../../static/img/member_male.png";
import GautamSir from "../../static/img/GautamSir.png";
import prem from "../../static/img/PremSir.png";
import preeti from "../../static/img/PritiMam.png";
// import Someshsir from "../../static/img/Someshsir.png";
import { Link } from "react-router-dom";
import aboutHeartImg from "../../static/img/Blue_Heart_Tick_about_us.png";
// import { FaGreaterThan, FaLessThan } from "react-icons/fa";

const About = () => {
  
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleHover = (index) => {
    setHoveredIndex(index);
  };

  const handleLeave = () => {
    setHoveredIndex(null);
  };
  
  const teamMembers = [
    {
      name: "Prem Prakash",
      position: "Chief Executive Officer",
      photo: prem,
      detail: "Mr. Prem Prakash, managing director of the company. He has more than 20 years of experience in strategic and operation management. He is jointly responsible for the overall supervision and handling day to day affairs of the company.",
      linkedin: "https://www.linkedin.com/example",
    },
    {
      name: "Preeti Kumari",
      position: "Director Finance",
      photo: preeti,
      detail: "Miss. Preeti is one of the founding member and Director of DIALMYTRIP. She has vast experience in the field of operation finance, financial planning and reporting, treasury and short term / Long term financial strategic.",
      linkedin: "https://www.linkedin.com/example",
    },
    {
      name: "Gautam Chatterjee",
      position: "Vice President",
      photo: GautamSir,
      detail: "A senior level executive who have been consistently contributing towards various organisational stints building a highly comprehensive professional experience in Operations & Process Management, Networking for Product Distribution, Business Data Analysis, Marketing & Channel Management, Monitoring of Regulatory Compliance, and much more.",
      linkedin: "https://www.linkedin.com/example",
    },

    // Add more team members here
  ];

  return (
    <>
      {/* Below is the first banner on about us  */}

      <div className="container outer_box_there_img">
        <div className=" row there_img"></div>
        <div className="row sibling_there_img">
          <div className="col-lg-6 col-md-12 left_content_about ">
            <div className="left_content_about_inner">
              <h1>About Us</h1>
              <p className="mb-5 about_text_p">
                About us - DMT Accountancy - We are a technology-driven
                platform, offering services that cover the legal needs of
                startups and established businesses. Some of our services
                include GST, incorporation, government registrations & filings,
                accounting, documentation, and annual compliances. In addition,
                we offer a wide range of services to individuals, such as
                property agreements and tax filings. Our mission is to provide
                one-stop solutions to individuals and businesses for all their
                legal and professional needs.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam, numquam! Eligendi animi, excepturi illo rerum esse sapiente fuga, mollitia veritatis perspiciatis ipsum atque accusantium, nam explicabo! Omnis soluta odit illo.
             Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi saepe quam iusto labore possimus nesciunt, illo vitae tempora odio consequatur temporibus sed autem neque animi suscipit dolorum, minima nisi quibusdam!
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Suscipit porro in, explicabo quidem unde at praesentium facere possimus tempora debitis sed nobis neque. Ex alias officiis illo sit corporis dolores? */}
          </div>
          {/* <div className="row">
            <div className="col-lg-6 col-md-12"></div>
          </div> */}
        </div>
      </div>
      {/* Below is the second banner in about page */}
      <div className="container outer_second_banner ">
        <div className="row ">
          <div className=" col-lg-12  ">
            <div className="">
              <div className="d-flex justify-content-center align-items-center">
                <img
                  className="align-items-center heart_about"
                  width="250rem"
                  src={aboutHeartImg}
                  alt="heart"
                />
              </div>

              <h1 className="text-center values_text_heading">
                Our Core Values
              </h1>
              <div className="container">
                <div className="row">
                  <div className="col-lg-3 col-md-6">
                    <div className="item">
                      <div className="content">
                        <h3><strong>Commitment</strong></h3>
                        <p className="about_Head_other">Endorsing Loyalty in our work.</p>
                      </div>
                      {/* <div className="content">
                        <h2>Item 1</h2>
                        <p>Content 2</p>
                      </div> */}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="item">
                      <div className="content">
                        <h3><strong>Customer Centricity</strong></h3>
                        <p className="about_Head_other">
                          Understanding customers needs and customize the
                          solutions accordingly.
                        </p>
                      </div>
                      {/* <div className="content">
                        <h2>Item 2</h2>
                        <p>Content 2</p>
                      </div> */}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="item">
                      <div className="content">
                        <h3><strong>Competence</strong></h3>
                        <p className="about_Head_other">Fostering a strong corporate culture.</p>
                      </div>
                      {/* <div className="content">
                        <h2>Item 3</h2>
                        <p>Content 2</p>
                      </div> */}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="item">
                      <div className="content">
                        <h3><strong>Team work</strong></h3>
                        <p className="about_Head_other">
                          Improving efficiency and productivity in all our
                          actions.
                        </p>
                      </div>
                      {/* <div className="content">
                        <h2>Item 4</h2>
                        <p>Content 2</p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row">
            <div className=" col-lg-12"></div>
          </div> */}
        </div>
      </div>
      {/* Below is the team details */}
      <div className="card_Container">
        {/* here is the team  */}
        <h1 className="text-center">Our Team</h1>

        <div className="card-list">
          <div
            className={`card_about_page ${hoveredIndex === 5 ? "flipped" : ""}`}
            onMouseEnter={() => handleHover(5)}
            onMouseLeave={handleLeave}
          >
            <div className="card-inner">
              <div className="card-front text-center">
                <img src={Deepaksir} alt="team" />
                <h4>Deepak Thakur</h4>
                <p>Head Account & Finance</p>
                <Link to='#' className="link_media">
                  <BsLinkedin color="blue"/>
                </Link>
              </div>
              <div className="card-back  text-center">
                <h4>Deepak Thakur</h4>
                <p className="about_Head">Mr. Deepak Kumar is a Highly motivated and Goal-oriented finance & Accounts professional. He has expertise in financial strategy & analysis with numerous years of rich experience. He is custodian of financial statement preparation & review and stabilisation of Annual & Quarterly Accounts.</p>
                <Link to='#' className="link_media">
                  <BsLinkedin color="blue"/>
                </Link>
              </div>
            </div>
          </div>
          {/* second card */}
          <div
            className={`card_about_page ${hoveredIndex === 6 ? "flipped" : ""}`}
            onMouseEnter={() => handleHover(6)}
            onMouseLeave={handleLeave}
          >
            <div className="card-inner">
              <div className="card-front text-center">
                <img src={ManojSir} alt="team" />
                <h4>Manoj Bhatt</h4>
                <p> Accounts Assistant</p>
                <Link to="#" className="link_media">
                  <BsLinkedin color="blue"/>
                </Link>
              </div>
              <div className="card-back  text-center">
                <h4>Manoj Bhatt</h4>
                <p className="about_Head">Mr. Manoj Bhatt is well seasoned Accounts personnel.He is very crucial into functioning of the Dept. </p>
                <Link to="#" className="link_media">
                  <BsLinkedin color="blue"/>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <h1 className="text-center">Board Of Advisors</h1>

        <div className="card-list">
          {teamMembers.map((card, index) => {
            return (
              <>
                <div
                  key={index}
                  className={`card_about_page ${hoveredIndex === index ? "flipped" : ""}`}
                  onMouseEnter={() => handleHover(index)}
                  onMouseLeave={handleLeave}
                >
                  <div className="card-inner">
                    <div className="card-front text-center">
                      <img src={card.photo} alt="team" />
                      <h4>{card.name}</h4>
                      <p>{card.position}</p>
                      <Link className="link_media" to={card.linkedin}>
                        <BsLinkedin color="blue"/>
                      </Link>
                    </div>
                    <div className="card-back  text-center">
                      <h4>{card.name}</h4>
                      <p className="about_Head">{card.detail}</p>
                      <Link className="link_media" to={card.linkedin}>
                        <BsLinkedin color="blue"/>
                      </Link>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default About;
