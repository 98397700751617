import React from "react";
import { Routes, Route } from "react-router-dom";
import BaseComponent from "./components/BaseComponent.jsx";
import Home from "./components/home/Home.jsx";
import About from "./components/about/About.jsx";
import Services from "./components/services/Services.jsx";
import Contact from "./components/contact/Contact.jsx";
import ServicesLayer1 from "./components/services/ServicesLayer1.jsx";
import ListServices from "./components/ListServices/ListServices.jsx";
import Careers from "./components/careers/Careers.jsx";

import ServiceInfo from "./components/service-info/ServiceInfo.jsx";

import TitleUpdater from "./components/common/TitleUpdater.jsx";

import { WebRoutes } from "./routes.jsx";
import { AiFillTrademarkCircle } from 'react-icons/ai';
import {GiBullseye} from 'react-icons/gi';
// import {TbDiamondFilled} from 'react-icons/tb';
import {BsHourglassSplit} from 'react-icons/bs';
import {RiFileSearchFill} from 'react-icons/ri';
// import {TbGeometry} from 'react-icons/tb';
import {FaStamp} from 'react-icons/fa';

import ScrollToTop from "./components/common/ScrollToTop";

function App() {
  // useEffect (()=>{
  //   console.log("hii");
  // })
  const servList = [
    {
        id:0,
      icon: GiBullseye,
      title: "Income Tax",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      subServices: [
        
          "Income Tax Return Filing",
            "ITR-1",
            "ITR-2",
            "ITR-3",
            "ITR-4",
            "ITR-5",
            "ITR-6",
            "ITR-7",
        
    
        "Lower TDS Certificate",
        "Tax Planning & Management",
        "TDS Return",
      ],
      detail_Texts:[
        "INCOME TAX",
        "Income Tax Return Filing",
        "Types of ITRs",
        "ITR-1",
        "ITR-2",
        "ITR-3",
        "ITR-4",
        "ITR-5",
        "ITR-6",
        "ITR-7",
        "Lower TDS Certificate",
        "Tax Planning & Management",
        "TDS Return",
      ],
      detail_Content:[
        " Income tax is a type of tax governments impose on income generated by businesses and individuals within their jurisdiction. It is used to fund public services, pay government obligations, and provide goods for citizens.",
        "ITR filing is the process through which a taxpayer must record his total income earned during the fiscal year. Individuals can file their taxes through the Income Tax Department's official portal. It has been notified in seven different forms.",
        "There are nine different types of ITR forms available for a taxpayer to use while filing his taxes. Individuals must use only the following forms for filing returns, according to the Central Board of Direct Taxes in India.",
        "This income tax return form is to be filed exclusively by an individual taxpayer. This form is intended for the following individuals. A person who makes their living by a salary or other means such as a pension, or from a single piece of real estate.	An individual who receives no income from any other business or from the sale of any assets.	Individuals who have not received any windfall wealth through lotteries, horse racing, or other sources.An individual whose agricultural income is less than Rs. 5,000 or a person whose income is derived from numerous investments or sources such as schemes, fixed deposits, and so on.Individuals that have no assets or property in countries other than India.or any individual who receives no revenue from any country other than India.",
        "The ITR-2 Form is a type of ITR form used by individuals who have earned money from the sale of assets or property.   This form is intended for the following individuals.People earn a living through a wage or other methods such as a pension.A person whose income is through capital gains.A person who has assets or earns a living in nations other than India.A person who generates money from more than one residential property.An individual who earns more than Rs 5,000 from agriculture.A person who makes a living from a windfall, such as lotteries or horse racing.",
        "The ITR-3 Form is useful for an individual taxpayer or a Hindu Undivided Family who is a partner in a firm but does not conduct any business via the firm.This form is typically filed by taxpayers whose only taxable income from the business is in the form of the following.Commission,Remuneration,Interest,Bonus,Salary",
        "This sort of ITR form is useful for persons who own a business or earn a living through a vocation.This form is appropriate to all types of businesses, undertakings, or professions, with no income restriction.",
        "The ITR-5 form is exclusively used to file income tax returns by the following organizations.Firms,Local authorities,Co-operative societies,Artificial Judicial persons,Body of individuals",

        "Except for firms or organizations that claim tax exemption under Section 11, all companies utilize the ITR-6 form. ",
        "Individuals and businesses who need to file income tax returns under the following sections must use ITR-7.Section 139(4A) - Individuals who receive income from property held in trust or legal obligation for the purpose of charity or religion may file returns under this section. Section 139(4B) - Under this section, political parties must submit returns if their total revenue exceeds the non-taxable limit. Section 139(4C) - The following entities are required to file returns under this section.Section 10 mentions any institution or group (23A).Any organization that is interested in scientific research.Any of the institutions listed in Section 10 (23B).",
        "Section 197 of the Income Tax Act, 1961 provides for the facility of NIL deduction of tax at source or at a deduction at a Lower rate of tax. To avail of this benefit the assesse whose TDS is likely to be deducted on certain receipts should make an application before the TDS Assessing Officer who has a jurisdiction over his/ her/ its case. The assesse/ deductee concerned may apply for a certificate for Nil or lower deduction of TDS on their receipts ",
        "Tax planning devises a person's financial affairs by taking advantage of all the allowable deductions, exemptions, allowances and rebates, legitimately, so that the tax liability is the least whereas Tax Management implies well timed and regular adherence to the tax laws and arrangement of financial affairs, in a way that reduces the taxes.",
        "TDS return can be best described as the quarterly statement or summary of all TDS-related transactions made during the specific quarter. Typically, it comprises details of the TDS collected and deposited to the Income Tax Authority by the deductor. The essential details disclosed in a TDS return statement include.Deductor and deductee's PAN.Particulars of TDS paid.Challan details Employers and  organisations with a valid TAN are qualified for filing TDS returns.Following are the different types of TDS return forms and their applications.1. 24Q It serves as a statement of tax deducted at source on salaries.2. 26Q	This form serves as a statement of tax deducted at source on non-salaried income.3. 26QB It serves as a statement of tax deducted against the payment of the immovable property.4. 27Q It is a certificate of tax deducted at source on interest, dividend payable to NRIs, foreign companies.5. 27EQ	This document is a statement of TCS.",
      ]
      
    },

    {
        id:1,
      icon: AiFillTrademarkCircle,
      title: "Goods & Service Tax",
      description:
        "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      subServices: [
        "GST Registration",
        
          "GST Return Filing",
            "GSTR-1",
            "GSTR-3B",
            "GSTR-9",
            "GSTR-9C",
            "CMP-08",
        
        
        "GST Refund",
      ],
      detail_Texts:[
        "Goods & Service Tax",
        "GST Registration",
        
        "GST Return Filing",
          "GSTR-1",
          "GSTR-3B",
          "GSTR-9",
          "GSTR-9C",
          "CMP-08",
      
      
        "GST Refund",




      ],
      detail_Content:[
        "Goods and service tax(GST) one of the country's most significant tax reforms. It is asingle, indirect tax levied by the Indian Government. This bill includes VAT, Central Excise Tax, Luxury Tax, Service Tax Act, Entertainment Tax and Entry Tax.Different Types of GST Tax.State Goods and Services Tax or SGST.Central Goods and Services Tax or CGST.Integrated Goods and Services Tax or IGST.",
        "The process by which a taxpayer gets registered under Goods and Service tax (GST) is known as GST registration. One a taxpayer is registered then he gets his GSTIN Number.",
        "A document that contains all the details of your sales, purchases, tax collected on sales (output tax), and tax paid on purchases (input tax) is knows as GST return. Once you file for the return, then you will have to pay the remaining tax liability that you owe the government.",
        "Outward supplies Return",
        "Inward and Outward supply summary",
        "Annual return for normal registered taxpayers",
        " Annual return for the taxpayers who are required to get the accounts audited by CA",
        "Is used to declare the details or summary of self-assessed tax which is payable for a given quarter by taxpayers who are registered as composition taxable person or taxpayer who have opted for composition levy.",
        "GST refund refers to the process wherein registered taxpayers can claim any excess charge they paid in terms of GST liability. It can be done post-submitting a refund application with the required details in the GST portal.",
      ]
    },
    {
        id:2,
      icon: FaStamp,
      title: "Ministry of Corporate Affairs",
      description:
        "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      subServices: [
        "Company Registration/Incorporation(Private)",
        "Company Registration/Incorporation(Public)",
        "NGO/Section-8 Company",
      ],
      detail_Texts:[
        "Ministry of Corporate Affairs",
        "Company Registration/Incorporation(Private)",
        "Company Registration/Incorporation(Public)",
        "NGO/Section-8 Company",
      ],
      detail_Content:[
        "The Ministry of Corporate Affairs (MCA) is vested with the powers of regulating the corporate affairs in India through the Companies Act of 1956, Companies Act, 2013, and other allied Acts. This article looks at the various functions of the regulatory body and the services provided by it.Company registration is the process of officially registering your business with the relevant government body. This usually involves filling out a registration form and paying a fee. The purpose of company registration is to give your business a legal identity. Company incorporation, on the other hand, is a more complex process. It involves creating a separate legal entity, known as a corporation, that is distinct from its owners. This means that the corporation can own property, enter into contracts, and even sue or be sued in its own name.",
        "Company registration is the process of officially registering your business with the relevant government body. This usually involves filling out a registration form and paying a fee. The purpose of company registration is to give your business a legal identity. Company incorporation, on the other hand, is a more complex process. It involves creating a separate legal entity, known as a corporation, that is distinct from its owners. This means that the corporation can own property, enter into contracts, and even sue or be sued in its own name.",
        "A public limited company is a voluntary association of members that are incorporated and, therefore has a separate legal existence and the liability of whose members is limited.Public limited companies are listed on the stock exchange where it’s share/stocks are traded publicly. To register a Public Limited Company in India there should be a minimum of seven members and there is no limit on the maximum number of members/shareholders for starting a Public Limited Company.",
        "Section 8 Company or a Non-Profit organisation (NPO) is a Company established for promoting commerce, art, science, religion, sports, education, research activities, charity or any other useful social object, provided the profits and donations is applied for promoting only the objects of the company and no dividend is paid to its members or owners.",
      ]
    },
    {
        id:3,
      icon: BsHourglassSplit,
      title: "Accounting",
      description:
        "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      subServices: ["lorem","ipsum","foflr","fdfgdf"],
      detail_Texts:[
        "lorem",
        "loremdfd",
        "dfgfgd",
        "fgfghf"
      ],
      detail_Content:[
        "lorem",
        "loremdfd",
        "dfgfgd",
        "fgfghf"
      ]
    },
    {
        id:4,
      icon: RiFileSearchFill,
      title: "Registration & Certificates",
      description:
        "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      subServices: [
        "80G Certificate",
        "12A Certificate",
        "Import Export Code(IEC),Shops & Establishment Certificate",
        "Copyright,Trademark",
      ],
      detail_Texts:[
        "Registration & Certificates",
        "80G Certificate",
        "12A Certificate",
        "Import Export Code(IEC),Shops & Establishment Certificate",
        "Copyright,Trademark",
      ],
      detail_Content:[
        "NGO is registered in the form of Section 8 Company under the Companies Act, 2013. Companies registered under this act are all not-for-profit and charitable trusts. The only difference between a trust or society and NGO is that the latter is registered under the Ministry of Corporate Affairs (MCA). In order to run as a non-governmental organisation (NGO) that is legally recognised in India, an organisation needs to be registered and certified in accordance with the applicable laws and regulations. In what follows, we will talk about the steps that need to be taken for NGOs in India to become certified as an NGO.1.	Step 1: Choose the Appropriate Legal Structure.2.	Step 2: Register the NGO.3.	Step 3: Obtain PAN and TAN Numbers.4.	Step 4: Obtaining 12A and 80G Certificates.5.	Step 5: Register for FCRA. 6.	Step 6: Register for GST. 7.	Step 7: Maintain Proper Record-Keeping",
        "The 80G and 12A Registration certificate helps an NGO to get income tax exemption. This exemption is granted to the NGO as well as to the donors. It is essential to obtain the certification immediately after the incorporation of the NGO. The main objective of 80G & 12A Registration is to encourage the donors to contribute to social work and make donations to the organisations having these certifications without the fear of any additional costs.",
        "The 80G and 12A Registration certificate helps an NGO to get income tax exemption. This exemption is granted to the NGO as well as to the donors. It is essential to obtain the certification immediately after the incorporation of the NGO. The main objective of 80G & 12A Registration is to encourage the donors to contribute to social work and make donations to the organisations having these certifications without the fear of any additional costs.",
        "An Import Export Code (IEC) is crucial business identification number which mandatory for export from India or Import to India. Import & Export Code is to be obtained by the business entity for import into or export from India. Import & Export Code is popularly known as the IEC number. Import & Export Code is a ten-digit unique number issued by the Directorate General of Foreign Trade (DGFT).The Certificate or the Shop License acts as a basic registration/license for the business. This Certificate is produced for obtaining many other business licenses and registrations. It serves as proof of the incorporation of commercial establishment or shops.",
        "Copyright is an automatic right assigned to the creator of any piece of original work. This could be one person, multiple people or a company. Copyright allows the owner to control how their work is used. Anyone who wants to reproduce copyrighted work must seek permission from the copyright holder, or check whether the owner has permitted particular use through a licence.A trademark is a unique symbol or word(s) used to represent a business or its products. Once registered, that same symbol or series of words cannot be used by any other. It's how customers recognize you in the marketplace and distinguish you from your competitors. The word “trademark” can refer to both trademarks and service marks.organization, forever, as long as it remains in use and proper paperwork and fees are paid."
      ]

    },
  ];
  // const [message, setMessage] = useState("");
  // Below useEffect is for the auto scroll testing 
  // useEffect(() => {
  //   const handleScroll = () => {
  //     console.log("hiiiiiii")
  //     const scrollValue = window.scrollY || window.pageYOffset;
  //     console.log('Scroll value:', scrollValue);
  //   };
  //   window.addEventListener('scroll', handleScroll);
  //   // Clean up the event listener
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  // useEffect(() => {
  //   fetch("http://localhost:8000/message")
  //     .then((res) => res.json())
  //     .then((data) => setMessage(data.message));
  // }, []);
  return (
    <>
  {/* <h1>{message}</h1> */}
  <TitleUpdater /> {/* Include the TitleUpdater component */}

    <ScrollToTop>

      <Routes>
      <Route path={WebRoutes.HOME} element={<BaseComponent />}>
      
       <Route path={WebRoutes.HOME} element={<Home/>}/>
       <Route path={WebRoutes.ABOUT_US} element={<About/>}/>

       <Route path={WebRoutes.SERVICES} element={<Services/>}/>
       <Route path={`${WebRoutes.SERVICES_LAYER1}/:index`} element={<ServicesLayer1 servList={servList}/>}/>
      <Route path={WebRoutes.LIST_SERVICES} element={<ListServices/>}/>
      <Route path={WebRoutes.SERVICE_INFO} element={<ServiceInfo/>}/>

      
       <Route path={WebRoutes.CAREERS} element={<Careers/>}/>
       <Route path={WebRoutes.CONTACT_US} element={<Contact/>}/>
      </Route>

      


      </Routes>
    </ScrollToTop>
    
    </>
  );
}

export default App;
