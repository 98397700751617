import React from "react";
import "./listServices.css";
const ListServices = () => {
  return (
    <>
      <div className="container list_services">
        <div className="contact-us-section top_section">
          <div className="container contact_box">
            <div className=" row services_img"></div>
            <div className="row sibling_there_img">
              <div className="col-lg-6 col-md-12 left_content_about ">
                {/* left_content_about_inner */}
                <div className="left_content_contact_inner ">
                  <h1>Services</h1>
                  <p className="about_text_p">What do we offer?</p>
                  <p className="about_text_p">
                    Customer satisfaction is our only motto, We are still in
                    touch with our first client long-lasting relationship with
                    our clients is our top most precedence.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12"></div>
              <div className="row">
                <div className="col-lg-6 col-md-12"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <h1 className="text-center "><span className="service_list_tag">All Services</span></h1>
          <div className="col-lg-4 col-md-12">
            <ul className="services_ul">
              <li className="li_ListService">Book-keeping </li>
              <li className="li_ListService">Auditing </li>
              <li className="li_ListService"> Income Tax Return</li>
              <li className="li_ListService"> Risk management Advisory</li>
              <li className="li_ListService">Food License</li>
              <li className="li_ListService">Trademark registration</li>
              <li className="li_ListService">MSME Registration</li>
              <li className="li_ListService"> ISO Registration</li>
              <li className="li_ListService">Company Formation Registration</li>
              <li className="li_ListService"> FDI Compliance</li>
              <li className="li_ListService"> Company Registration</li>
              <li className="li_ListService"> CA Services</li>
              <li className="li_ListService"> Annual Filing</li>

              <li className="li_ListService">Online Startup Registration</li>
            </ul>
          </div>
          <div className="col-lg-4 col-md-12">
            <ul className="services_ul">
              <li className="li_ListService">Business Analysis </li>
              <li className="li_ListService">Foreign Remittance Compliance </li>
              <li className="li_ListService">Tax Planning </li>
              <li className="li_ListService">Company Closure </li>
              <li className="li_ListService">Startup Recognition</li>
              <li className="li_ListService"> CA Certification</li>
              <li className="li_ListService">Company Annual filing</li>
              <li className="li_ListService"> LLP Annual Filing</li>
              <li className="li_ListService">Trust Formation</li>
              <li className="li_ListService"> FSSAI Registration</li>
              <li className="li_ListService">GST Registration</li>
              <li className="li_ListService"> Proprietorship Firm</li>
              <li className="li_ListService"> Warehouse Registration</li>
              <li className="li_ListService">
                Private Limited Company Registration
              </li>
            </ul>
          </div>
          <div className="col-lg-4 col-md-12">
            <ul className="services_ul">
              <li className="li_ListService">Financial Reporting </li>
              <li className="li_ListService">Income Tax consulting </li>
              <li className="li_ListService">GST Consulting </li>
              <li className="li_ListService">TDS Consulting </li>
              <li className="li_ListService"> Payroll Consulting</li>
              <li className="li_ListService">Investment Advisory</li>
              <li className="li_ListService"> Proprietorship</li>

              <li className="li_ListService">Tax Filing Expert</li>
              <li className="li_ListService">FDI Advisory</li>
              <li className="li_ListService">GST Return</li>
              <li className="li_ListService">GST Registration Filing</li>

              <li className="li_ListService"> Limited Liability Partnership</li>
             

              <li className="li_ListService">GST Returns</li>
              <li className="li_ListService">
                ROC Compliance of Private Limited
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="bottom_service_banner">
          <div className="col-lg-6 col-md-12">
           <span>Want help? Call us.</span><br/>
           <span className="thick_font">+91 9355100668, 0120-4151488</span>

          </div>
        
          </div>
        </div>
      </div>
    </>
  );
};

export default ListServices;
