import React from "react";
import "./careers.css";
import { Link } from "react-router-dom";
import { SlNotebook } from "react-icons/sl";
import { BsArrowRight } from "react-icons/bs";
import {AiOutlineClockCircle} from "react-icons/ai"
const Careers = () => {
  return (
    <>
      <div className="container outer_box_there_img">
        <div className=" row there_img_careers"></div>
        <div className="row sibling_there_img">
          <div className="col-lg-6 col-md-12 left_content_about ">
            <div className="left_content_about_inner">
              <h1>Careers</h1>
              <p className="mb-5 about_text_p">Come work with us!</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-12"></div>
          <div className="row">
            <div className="col-lg-6 col-md-12"></div>
          </div>
        </div>
      </div>
      <div className="container">
        <h2 className="text-center opening_head">Recent Openings</h2>
        <div className="row">
          <div className="col-lg-4 col-md-6 post_box">
            <div className="row">
              <div className="need_row">
                <p>Coming Soon...</p>
                <SlNotebook color="orangeRed" size={"30px"} />
              </div>
              <div className="detail_post">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Distinctio excepturi reiciendis iste quibusdam adipisci odit
                  officia facilis modi, dolor iure dignissimos assumenda minima
                  atque, impedit harum iusto illo error aspernatur!
                </p>
              </div>
              <div className="apply_btn">
                <Link to="mailto:your-email@example.com" className="apply_btn_link">
                  <div>
                    Apply <BsArrowRight />
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 post_box">
            <div className="row">
              <div className="need_row">
                <p>Coming Soon...</p>
                <SlNotebook color="orangeRed" size={"30px"} />
              </div>
              <div className="detail_post">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Distinctio excepturi reiciendis iste quibusdam adipisci odit
                  officia facilis modi, dolor iure dignissimos assumenda minima
                  atque, impedit harum iusto illo error aspernatur!
                </p>
              </div>
              <div className="apply_btn">
                <Link to="mailto:your-email@example.com"  className="apply_btn_link">
                  <div>
                    Apply <BsArrowRight />
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 post_box">
            <div className="row">
              <div className="need_row">
                <p>Coming Soon...</p>
                <SlNotebook color="orangeRed" size={"30px"} />
              </div>
              <div className="detail_post">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Distinctio excepturi reiciendis iste quibusdam adipisci odit
                  officia facilis modi, dolor iure dignissimos assumenda minima
                  atque, impedit harum iusto illo error aspernatur!
                </p>
              </div>
              <div className="apply_btn">
                <Link to="mailto:your-email@example.com"  className="apply_btn_link">
                  <div>
                    Apply <BsArrowRight />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <h2 className="latest_openings">Latest Openings</h2>
          <div>
            <ul>
                <li className="custom-li">
                    <Link className="apply_btn_link"><p>Coming Soon...</p></Link><AiOutlineClockCircle/><span className="span_left_margin">3 months ago</span>
                </li>
                <li className="custom-li">
                <Link className="apply_btn_link"><p c>Coming Soon...</p></Link><AiOutlineClockCircle/><span className="span_left_margin">3 months ago</span>
                </li>
              

            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Careers;
