import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  AiFillFacebook,
  AiFillYoutube,
  AiFillInstagram,
  AiOutlineTwitter,
  AiFillLinkedin,
} from "react-icons/ai";
// import { HiLocationMarker } from "react-icons/hi";
import { BsFillPhoneFill, BsGlobe } from "react-icons/bs";
import { AiFillMail } from "react-icons/ai";
import { WebRoutes } from "../../routes.jsx";
// import { TbWorld } from "react-icons/tb";
import { MdLocationPin } from "react-icons/md";


import "./footer.css";

const Footer = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    option:''
  });
  
const [isSubmitDisabled,setIsSubmitDisabled] = useState(true);

  const formRef = useRef(null);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
 //handle change
 const handleChange = (event) => {
  const { name, value } = event.target;
  if(name === 'phone'){
    // console.log(value.length)
    const numericValue = value.replace(/\D/g,"");

 
  if (numericValue.length < 10 || !['6', '7', '8', '9'].includes(numericValue[0])) {
    setFormData((prevData) => ({
      ...prevData,
      [name]: '',
    }));
    // setIsSubmitDisabled(true);
  } else {
    setFormData((prevData) => ({
      ...prevData,
      [name]: numericValue,
    }));
    // setIsSubmitDisabled(false);
  }
}
  else if(name === 'option'){
    if(value === ''){
      setIsSubmitDisabled(true);
    }else{
      setIsSubmitDisabled(false);
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  }
  else{
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }
};
// const sendFormData = (formData) =>{
//   // Make an HTTP request to the backend
//   fetch('https://dmt-backend-database.onrender.com/form-data', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(formData),
//   })
//     .then((response) => response.json())
//     .then((data) => {
//       // Handle the response from the backend
//       console.log(data);
//     })
//     .catch((error) => {
//       // Handle any errors
//       console.error(error);
//     });
// }
  const handleSubmit = (event) => {
    console.log("footer");
    event.preventDefault(); // prevent page refresh

   
    if(formData.name !== '' && formData.email !== '' && formData.option !== '' && formData.phone !== ''){
      const config = {
        SecureToken: "22f70113-85a9-418b-ba9c-074e024ade46",
    To: "rajeshquicksilver56@gmail.com",
    From: formData.email,
    Subject: "Query Form",
    Body: `<html>
        <table >
        <tr>
        <td><img src="https://dmtgroup.in/img/logo.png" alt="logo"/></td>
        <td></td>
        <td>${formData.name} Contacted you via Email</td>
        </tr>
        <tr style="border: 1px solid black;">
        <td style="border: 1px solid black;">Name of User:</td>
        <td style="border: 1px solid black;">Email of the User:</td>
        <td style="border: 1px solid black;">Service Selected by User:</td>
        <td style="border: 1px solid black;">User Phone Number:</td>
  
      </tr>
      <tr>
        <td style="border: 1px solid black;">${formData.name}</td>
        <td style="border: 1px solid black;">${formData.email}</td>
        <td style="border: 1px solid black;">${formData.option}</td>
        <td style="border: 1px solid black;">${formData.phone}</td>
  
      </tr> <table/>
  </html>`
  }
  if(window.Email){
    window.Email.send(config).then((message)=>alert(message));
  }
    // sendFormData(formData);
  
    }else{
      alert("Please fill the form completely")
    }
    // formRef.current.reset();
    setFormData({
      name: '',
      email: '',
      phone: '',
      option:''
    });
  };
  return (
    <>
      {/* <!-- ======= Footer ======= --> */}
      <footer className="footer foot_whole">
        <div className="container">
          <div className="row text-center for_bottom_border">
            <div className="col-md-4 for_bottom_border">
            
              <h2 className="foot_headers">Contact</h2>
              <div className="contact_elements">
                <span ><MdLocationPin size={"20px"} color="#9336B4" />&nbsp; &nbsp;516, Vishal Chamber, Sector-18, Noida-201301,<br/> Uttar Pradesh</span>
              </div>
              <div className="contact_elements">
                <span><BsFillPhoneFill size={"20px"} color="#9336B4" />&nbsp; &nbsp;+91 8368408171 , 0120-4151488</span>
              </div>
              <div className="contact_elements">
              <span><AiFillMail size={"20px"} color="#9336B4" />&nbsp; &nbsp;info@dmtaccountancy.com</span>
              </div>
              <div className="contact_elements">
              <span><BsGlobe size={"20px"} color="#9336B4" />&nbsp; &nbsp;dmtaccountancy.com</span>
              </div>
              <div className="contact_elements">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <Link
                    to="https://www.facebook.com/dmt.dialmytrip"
                    target="_blank"
                  >
                    <span className="icon_foot">
                      <AiFillFacebook size={"20px"} color="gray" />
                    </span>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to="https://www.instagram.com/dialmytrip/"
                    target="_blank"
                  >
                    <span className="icon_foot_insta">
                      <AiFillInstagram size={"20px"} color="gray" />
                    </span>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="https://twitter.com/dialmytrip" target="_blank">
                    <span className="icon_foot_twit">
                      <AiOutlineTwitter size={"20px"} color="gray" />
                    </span>{" "}
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to="https://www.linkedin.com/company/dialmytrip/"
                    target="_blank"
                  >
                    <span className="icon_foot_linkedin">
                      <AiFillLinkedin size={"20px"} color="gray" />
                    </span>{" "}
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="https://www.youtube.com/dialmytrip" target="_blank">
                    <span className="icon_foot_youtube">
                      <AiFillYoutube size={"20px"} color="gray" />
                    </span>
                  </Link>
                </li>
              </ul>
              </div>
            </div>

            <div className="col-md-4 for_Left_border for_bottom_border ">
              <h2 className="foot_headers">Quick Links</h2>
              <Link to={WebRoutes.HOME} className="quick_links"><p>Home</p></Link>
              <Link to={WebRoutes.ABOUT_US} className="quick_links"><p>About Us</p></Link>
              <Link to={WebRoutes.CONTACT_US} className="quick_links"><p>Contact</p></Link>
              <Link to={WebRoutes.SERVICES} className="quick_links"><p>Services</p></Link>
              <Link to={WebRoutes.CAREERS} className="quick_links"><p>Careers</p></Link>
              <Link to="#" className="quick_links"><p>Term & Conditions</p></Link>

            </div>
          
            <div className=" col-md-4  for_Left_border for_bottom_border ">
              <h2 className="foot_headers">TALK TO US!</h2>
              <div className="">
                <form onSubmit={handleSubmit} ref={formRef}>
                <div className="mb-3">
                <input type="text"
                        placeholder="Enter Your Name" 
                        className="form-control"
                        
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                         required />
                </div>
                 
                  <div className="mb-3">
                <input type="email"
                        placeholder="Enter Your Email" 
                        className="form-control"
                        
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                         required />
                </div>

                
                    <div className="mb-3">
                <input type="phone"
                        placeholder="Enter Your Phone" 
                        className="form-control"
                        maxLength={10}
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                         required />
                </div>
                  
                  <div className="mb-3">
                   
                      <select
                         className="form-control"
                         name="option"
                         value={formData.option}
                         onChange={handleChange}
                        required
                      >
                        <option>Services</option>
                        <option>Income Tax</option>
                        <option>Goods & Service Tax</option>
                        <option>Ministry of Corporate Affairs</option>
                        <option>Accounting</option>
                        <option>Registration & Certificates</option>
                      </select>
                    
                  </div>
                  <div className=" ">
                    <div className="">
                      <button className="btn btn-success" disabled={isSubmitDisabled}>
                        Let's Connect
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="container  text-center font_btm ">
            <div className="row foot_down ">
              <div className="col-md-4    for_nowrap for_div_margin ">
                {/* <p className="col-md-1  for_nowrap for_margin "><Link className="foot_link">Terms of use</Link></p> */}
                <p className="text-center  for_nowrap">
                  <Link className="foot_link ">Privacy Policy</Link>
                </p>
                {/* <p className="col-md-1 for_Left_border for_nowrap for_margin "> <Link className="foot_link"> Refund Policy</Link></p> */}
              </div>
              <div className="col-md-4  for_nowrap foot_link">
                <p>
                  &copy;{currentYear} DMT Accountancy Pvt Ltd. All Rights
                  Reserved &copy;
                </p>
              </div>
              <div className="col-md-4  for_nowrap foot_link">
                <p>Designed by DMT Media & Marketing Ltd.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
